import { makePersisted } from "@solid-primitives/storage";
import { A, action, useSearchParams } from "@solidjs/router";
import { createSignal, onMount } from "solid-js";
import { createStore } from "solid-js/store";

import { customerStore, StoredCustomer } from "../lib/user-store";
import { identifyByUniqueId, track } from "~/lib/customer-io";
import { Title } from "@solidjs/meta";
import { logException } from "~/lib/error-logger-client";
const [storedCustomer, setStoredCustomer] = customerStore;

export default function Confirm() {
  const startYear = 1833;
  const endYear: number = 2024;
  const defaultYear = 2004;
  const maxExperience = endYear - startYear;
  const experienceLimit: number = 110 - 16;
  const [customerName, setCustomerName] = createSignal("");
  const [isWorkingAsDev, setIsWorkingAsDev] = createSignal(true);
  const [isFormAltered, setIsFormAltered] = createSignal(false);
  const [hasAnsweredFirstQuestion, setHasAnsweredFirstQuestion] =
    createSignal(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [yearStarted, setYearStarted] = createSignal<number>(defaultYear);
  const yearsAgo = (): number => endYear - yearStarted();
  const percentOnSlider = () =>
    (Math.floor((yearsAgo() / maxExperience) * 10000) / 100) * 0.96 + 5;
  const yearsAgoStr = () => {
    if (yearsAgo() === maxExperience) {
      return `hi ada`;
    } else if (yearsAgo() > experienceLimit) {
      return `☠️ rip`;
    } else if (yearsAgo() === 0) {
      return `This year!`;
    } else if (yearsAgo() === 1) {
      return `${yearsAgo()} year ago`;
    } else if (yearsAgo() > 1) {
      return `${yearsAgo()} years ago`;
    }
  };

  onMount(() => {
    setCustomerName(storedCustomer.first_name);
    const log = searchParams;

    const customerIdProvided = searchParams.id;

    if (!customerIdProvided) {
      console.error("No customer id provided in query params");
      return;
    }

    identifyByUniqueId(customerIdProvided); // identify on client side. THis is  in case the user opens in another browser from their confirm email

    fetch("/api/subscription_confirm", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        user_id: searchParams.id,
      }),
    })
      .then((response) => {
        response.json().then((result) => {
          if (!result.success) {
            logException(
              new Error(
                `Call to /api/subscription_confirm was not sucessful, message was: ${result.message}`
              ),
              true
            );
          }
        });
      })
      .catch((e) => {
        logException(e, true);
      });
  });

  const yearStartedDevSubmitAction = action(async (formData: FormData) => {
    const yearStartedDev = Number(formData.get("year_started_dev"));
    const hasNotStartedDev = Boolean(formData.get("has_not_started_dev"));

    console.log("yearstarteddev", yearStartedDev);
    console.log("hasNotStartedDev", hasNotStartedDev);

    if (hasNotStartedDev) {
      track("survey-answer", {
        question_slug: "year-started-developing",
        started_professionally: false,
        year_started: 0,
      });
    } else if (yearStartedDev) {
      track("survey-answer", {
        question_slug: "year-started-developing",
        started_professionally: true,
        year_started: yearStartedDev,
      });
    }
    setHasAnsweredFirstQuestion(true);
    console.log("hasNotStartedDev", hasNotStartedDev);
  }, "years-started-dev-submit-action");

  //if (storedCustomer.first_name) name = storedCustomer.first_name;

  return (
    <>
      <Title>Email confirmed</Title>

      <main class="grid grid-cols-1 place-items-center w-full">
        <div class="grid grid-cols-1 pt-10 pr-8 pl-8 max-w-screen-xl  ">
          {!hasAnsweredFirstQuestion() ? (
            <div class={` mb-4`}>
              <p class="text-4xl text-white font-serif my-6 pt-8 pr-2">
                <span class="fff-th">Boom</span> 💛 You are now subscribed to
                funfun.email!
              </p>
            </div>
          ) : (
            <div class={`px-8 mb-4 max-w-screen-md`}>
              <p class="text-4xl text-white font-serif my-6 pt-8 pr-2 ">
                Hej <span class="fff-th">{customerName()}</span>, it's really
                valuable for me to get to know you, so{" "}
                <span class="fff-th">THANK YOUUUU</span>!
              </p>
              <p class="pb-4">
                Partially, it's valuable when I approach new sponsors to know
                some demographic info, and it is also good when planning
                content.
              </p>
              <p class="pb-4">
                But most importantly <b class="fff-th">it puts a face on you</b>{" "}
                as an audience and makes it feel like I make videos for people
                and not just unique visitor counters.
              </p>
              <p class="pb-4">
                <b class="fff-th ">
                  Would you mind answering just a few more questions?
                </b>
              </p>
              <A href="/survey">
                <button
                  id="submit-subscribe-form-button"
                  class="fff-button disabled:opacity-75"
                  type="submit"
                  style="height: 55px"
                >
                  {"Take the 3-question survey"}
                </button>
              </A>
            </div>
          )}
          <form
            id="subscribe-form"
            class=""
            method="post"
            action={yearStartedDevSubmitAction}
          >
            <div class={`mb-0 ${hasAnsweredFirstQuestion() && "invisible"}`}>
              <div>
                <div class="pt-8">
                  <div class="max-w-xl pb-3">
                    <label class="text-3xl  font-serif" for="city">
                      By the way,&nbsp;
                      {customerName() && (
                        <span>
                          <span class="fff-th">{customerName()}</span>,&nbsp;
                        </span>
                      )}
                      what year did you start working as a developer?
                    </label>
                  </div>
                  {isWorkingAsDev() && (
                    <div class={`flex flex-row`}>
                      <div class="basis-3/12">
                        <div class="text-3xl text-white font-serif py-3 fff-th">
                          {yearsAgoStr()}
                        </div>
                      </div>
                      <div class="basis-9/12">
                        <input
                          id="year_started_dev"
                          name="year_started_dev"
                          type="range"
                          min={startYear}
                          max={endYear}
                          style="width: 100%;direction: rtl"
                          class="fff-range w-full  appearance-none px-3 text-gray-700 pt-8 pb-0 "
                          value={defaultYear}
                          onInput={(e) => {
                            setIsFormAltered(true);
                            setYearStarted(parseInt(e.target.value, 10));
                          }}
                        ></input>
                        <div class="yearSliderLabelContainer">
                          <div
                            class="yearSliderLabel"
                            style={`left: ${percentOnSlider()}%`}
                          >
                            {yearStarted()}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div class="pt-0 pb-8">
                    <label class="text-1xl font-serif ">
                      <input
                        id="has_not_started_dev"
                        name="has_not_started_dev"
                        type="checkbox"
                        class="accent-yellow-300 size-6 align-middle shadow  border rounded   focus:shadow-outline"
                        onChange={(e) => {
                          setIsFormAltered(true);
                          console.log("e.target.value", e.target.checked);
                          setIsWorkingAsDev(!e.target.checked);
                        }}
                      />{" "}
                      <span class="pl-1">
                        I have not worked professionally as a developer (yet)
                      </span>
                    </label>
                  </div>
                  <button
                    id="submit-subscribe-form-button"
                    class="fff-button disabled:opacity-75"
                    type="submit"
                    style="height: 55px"
                    disabled={!isFormAltered()}
                  >
                    {"Submit answer"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
    </>
  );
}
